<template>
  <base-section
    id="map"
    space="0"
  >
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.0233495340717!2d101.01809921541295!3d4.015610048333144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cb1540179a7997%3A0xee47e280d9a20b1a!2sHowellcare%20Industries%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1648534070633!5m2!1sen!2smy"
      width="100%"
      height="500"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMap',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
